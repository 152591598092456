import { Component, Vue } from "vue-property-decorator";
import DateService from "@/services/DateService";

@Component({})
export class DomainMixin extends Vue {
  protected getDomainSslExpireAtClass(expiresAt: string): string {
    const expiresInDays = DateService.getDaysUntil(expiresAt);

    if (expiresInDays > 30) {
      return "has-text-success";
    } else if (expiresInDays > 0) {
      return "has-text-warning";
    } else {
      return "has-text-error";
    }
  }

  protected getDaysUntil(untilDate: string) {
    return DateService.getDaysUntil(untilDate);
  }
}
