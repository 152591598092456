
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { CustomerStoreMixin } from "@/mixins/Stores/CustomerStoreMixin";
import { Customer } from "@/config/Modules";
import ICustomer from "@/interfaces/ICustomer";
import DashboardCard from "@/components/modules/customer/DashboardCard.vue";
import { DomainMixin } from "@/mixins/Global/DomainMixin";

@Component({
  components: { DashboardCard },
  mixins: [DomainMixin],
})
export default class CustomerDashboard extends mixins(
  CustomerStoreMixin,
  DomainMixin
) {
  @Prop({ required: true })
  protected customer!: ICustomer;

  @Watch("customer", { immediate: true, deep: true })
  protected onCustomerChanged(customer: ICustomer | null) {
    if (customer?.id) {
      this.loadCustomerDashboardData(customer);
    }
  }

  protected domainsCardTableColumns: Array<any> = [
    { field: "url", label: "URL" },
  ];
}
